<template>
  <div id="app">
    <navbar />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <notice />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Notice from "./components/Notice.vue";

export default {
  name: "App",
  components: { Navbar, Notice },
};
</script>

<style lang="scss">
#app {
  @extend .font-inter;

  margin: 0;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
</style>
