import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/works",
    name: "Works",
    component: () => import("@/views/Works.vue"),
  },
  {
    path: "/achievements",
    name: "Achievements",
    component: () => import("@/views/Achievements.vue"),
  },
  {
    path: "/uses",
    name: "Things I Use",
    component: () => import("@/views/Uses.vue"),
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: () => import("@/views/Privacy.vue"),
  },
  {
    path: "*",
    name: "Not Found",
    component: () => import("@/views/NotFound.vue"),
  },
];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  /**
   * Update the title based on the name of the component.
   */
  // Update the titke
  document.title = `${to.name} - Deepjyoti Barman`;

  next();
});

export default router;
