<template>
  <div class="notice__container fixed">
    <transition name="slide">
      <div
        v-if="canShowNotice"
        class="
          notice--content
          bg-yellow-100
          flex
          justify-between
          md:px-8
          px-4
          md:py-4
          py-2
          relative
        "
      >
        <div class="text--content md:w-1/2 w-full text-sm">
          I, and the services I use, use cookies for analytics purposes. By
          using my website and services, you agree to the use of cookies as
          described in our
          <a
            href="https://deepjyoti30.dev/privacy-policy"
            target="_blank"
            ref="noreferrer noopener"
            >Privacy Policy</a
          >.
        </div>
        <div class="close--btn absolute md:py-4 py-2 md:px-4 px-2">
          <button
            type="button"
            title="Close this notice"
            aria-label="Close"
            @click="hideNotice"
          >
            <XIcon size="1x" />
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { XIcon } from "vue-feather-icons";

export default {
  name: "Notice",
  components: { XIcon },
  data() {
    return {
      canShowNotice: false,
    };
  },
  methods: {
    hideNotice: function () {
      /**
       * Hide the notice when the user clicks the X Icon.
       *
       * We are just supposed to hide self and write the
       * setting to the localstorage. This way, from the next
       * time, the setting will automatically hide the notice.
       */
      this.canShowNotice = false;

      // Also store the setting
      localStorage.setItem("hide-notice", "t");
    },
    checkNoticeSetting: function () {
      /**
       * Check the setting if it is stored or not.
       *
       * If it is stored, that means we don't need to show the notice
       * again. Else we need to show it.
       */
      const setting = localStorage.getItem("hide-notice");

      if (setting) return;
      this.canShowNotice = true;
    },
  },
  mounted() {
    this.checkNoticeSetting();
  },
  computed: {
    showNotice() {
      return this.canShowNotice;
    },
  },
};
</script>

<style lang="scss" scoped>
.notice__container {
  bottom: 0;
  left: 0;
  width: 100%;

  .text--content {
    a {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .close--btn {
    right: 0;
    top: 0;
  }
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateY(6rem);
}

.slide-enter-to,
.slide-leave {
  opacity: 1;
  transform: translateY(0);
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity, transform 0.2s ease;
}
</style>
