<template>
  <nav class="navbar--wrapper w-full border-b">
    <div
      class="
        navbar
        xl:w-3/5
        md:w-11/12
        w-full
        mr-auto
        ml-auto
        md:px-0
        md:py-3
        flex flex-wrap
        justify-between
        items-center
        bg-white
      "
    >
      <router-link
        to="/"
        class="logo__container block md:py-2 py-4 px-4 flex items-center"
      >
        <div class="logo mr-4">
          <img src="img/logo.png" alt="Logo" width="30" />
        </div>
        <div class="text text-xl font-medium md:block hidden">
          Deepjyoti Barman
        </div>
      </router-link>
      <div
        class="
          links__container
          inline-flex
          items-center
          text-lg
          dm-sans
          font-medium
          text-gray-600
        "
      >
        <router-link to="/" class="link">About</router-link>
        <router-link to="/works" class="link">Works</router-link>
        <a
          href="https://blog.deepjyoti30.dev"
          class="inline-flex items-center link"
          target="_blank"
          rel="noopener"
          >Blog =>
        </a>
      </div>
      <button
        type="button"
        @click="toggleBar"
        aria-label="Toggle Menu"
        class="md:hidden block focus:outline-none outline-none p-4"
      >
        <div class="icon">
          <x-icon v-if="getExpandNavbar" />
          <menu-icon v-else />
        </div>
      </button>
    </div>
    <transition name="navbar-expand">
      <div
        v-if="getExpandNavbar"
        class="navbar-expanded__content text-2xl font-medium"
      >
        <div class="wrapper pt-12 px-2 flex flex-col justify-between">
          <div class="links--content">
            <router-link to="/" class="link" @click.native="toggleBar">
              About</router-link
            >
            <router-link to="/works" class="link" @click.native="toggleBar">
              Works</router-link
            >
            <router-link
              to="/achievements"
              class="link"
              @click.native="toggleBar"
            >
              Achievements</router-link
            >
            <router-link to="/uses" class="link" @click.native="toggleBar">
              Uses</router-link
            >
            <a
              href="https://blog.deepjyoti30.dev"
              target="_blank"
              rel="noopener"
              class="link"
              @click="toggleBar"
            >
              <span class="text--content inline-flex"> Blog => </span>
            </a>
          </div>
          <div
            class="
              text-xl text-center
              font-medium
              flex
              items-center
              justify-center
              text-gray-700
              font-jetbrains-mono
            "
          >
            <span class="text">*[ </span>
            <CoffeeIcon size="0.9x" class="mx-2" />
            <span>, </span>
            <CodeIcon size="0.9x" class="mx-2" />
            <span> ]</span>
            <span class="mx-2">-></span>
            <span>deepjyoti30</span>
          </div>
        </div>
      </div>
    </transition>
  </nav>
</template>

<script>
import { MenuIcon, XIcon, CoffeeIcon, CodeIcon } from "vue-feather-icons";

export default {
  name: "Navbar",
  components: {
    MenuIcon,
    XIcon,
    CoffeeIcon,
    CodeIcon,
  },
  data: () => {
    return {
      expandNavbar: false,
    };
  },
  methods: {
    toggleBar: function () {
      // Toggle the visibility of the bar
      this.expandNavbar = !this.expandNavbar;
      this.handleScroll();
    },
    handleScroll: function () {
      /**
       * Handle the scroll of the body. If the navbar is shown,
       * we will stop the scroll. Else enabled it.
       */
      document.body.style.position = this.expandNavbar ? "fixed" : "static";
    },
  },
  computed: {
    getExpandNavbar() {
      return this.expandNavbar;
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar--wrapper {
  .navbar {
    z-index: 5;

    .links__container {
      .link {
        @apply ml-12;

        &:first-child {
          @apply ml-0;
        }

        &.router-link-exact-active {
          color: $teal;
        }

        @extend .link-highlight;
      }
    }

    .external--indicator {
      @apply ml-2;
    }

    @media only screen and (max-width: 768px) {
      .links__container {
        display: none;
      }
    }
  }

  .navbar-expanded__content {
    width: 100%;
    height: 95vh;
    overflow: hidden;
    z-index: 1;

    .wrapper {
      height: 85%;
    }

    .link {
      display: block;
      border-bottom-width: 1.5px;

      @apply py-7;
      @apply pl-7;
      @apply border-gray-200;
      @apply text-gray-600;

      &.router-link-exact-active {
        color: $teal;
      }

      @extend .link-highlight;
    }
  }

  .navbar-expand-enter {
    opacity: 0;
    height: 0;
  }
  .navbar-expand-enter-to {
    opacity: 1;
    height: 95vh;
  }
  .navbar-expand-leave {
    opacity: 1;
    height: 95vh;
  }
  .navbar-expand-leave-to {
    opacity: 1;
    height: 0;
  }
  .navbar-expand-enter-active,
  .navbar-expand-leave-active {
    transition: opacity, height 200ms ease;
  }
}
</style>
